import { type CSSProperties } from 'vue'

type Axis = 'x' | 'y'
type Coords = { [key in Axis]: number }
type ProcessedEvent = TouchEvent | MouseEvent
type ScrollParams = NonNullable<Parameters<typeof useScroll>[1]>
type ArrivedState = ReturnType<typeof useScroll>['arrivedState']

export function useDraggableScrolling(
  elemRef: Ref<HTMLElement | null>,
  processedAxises: Axis[],
  mode: 'scroll' | 'transition',
  scrollParams?: ScrollParams,
) {
  const startPosCoords: Coords = { x: 0, y: 0 }
  const dragDelta: Coords = { x: 0, y: 0 }
  const maxTransition: Coords = { x: 0, y: 0 }

  let initialX = 0
  let initialY = 0
  let isTouch = false
  let moveEventName!: 'touchmove' | 'mousemove'
  let endEventName!: 'touchend' | 'mouseup'

  let x = ref<number>(0)
  let y = ref<number>(0)
  let isDragging = ref<boolean>(false)
  let arrivedState!: ArrivedState
  if (mode === 'scroll') {
    const scrollData = useScroll(elemRef, {
      ...scrollParams,
      behavior: scrollParams?.behavior ?? 'instant',
    })
    x = scrollData.x
    y = scrollData.y
    isDragging = scrollData.isScrolling
    arrivedState = scrollData.arrivedState
  }

  const draggingStyles = computed<CSSProperties>(() => {
    const isProcessingX = processedAxises.includes('x')
    const isProcessingY = processedAxises.includes('y')
    if (mode === 'scroll') {
      return {
        scrollbarColor: 'transparent transparent',
        scrollbarWidth: 'none',
        overflowX: isProcessingX ? 'scroll' : 'hidden',
        overflowY: isProcessingY ? 'scroll' : 'hidden',
      }
    }
    const usedX = isProcessingX ? x.value : 0
    const usedY = isProcessingY ? y.value : 0
    return {
      width: 'fit-content',
      transition: 'transform 0.2s ease-out',
      transform: `translate(${usedX}px, ${usedY}px)`,
    }
  })

  function defineIsTouchEvent(e: ProcessedEvent): e is TouchEvent {
    isTouch = 'touches' in e
    return isTouch
  }

  function getCoordsOfEvent(e: ProcessedEvent): Coords {
    const source = defineIsTouchEvent(e) ? e.touches[0] : e
    const { clientX, clientY } = source
    return { x: clientX, y: clientY }
  }

  function setEventsNames(): void {
    moveEventName = isTouch ? 'touchmove' : 'mousemove'
    endEventName = isTouch ? 'touchend' : 'mouseup'
  }

  function processTransitionLimiting(): void {
    // Чтобы не уезжал вправо при нулевой прокрутке
    if (x.value > 0) {
      x.value = 0
    }
    if (y.value > 0) {
      y.value = 0
    }
    // Чтобы не уезжал влево дальше максимальной прокрутки
    if (Math.abs(x.value) > maxTransition.x) {
      x.value = -maxTransition.x
    }
    if (Math.abs(y.value) > maxTransition.y) {
      y.value = -maxTransition.y
    }
  }

  function calcTransitionLimits(): void {
    const { value: elem } = elemRef as Ref<HTMLElement>
    const parentElem = elem.parentElement as HTMLElement
    const { width: pWidth, height: pHeight } =
      parentElem.getBoundingClientRect()
    const pcs = getComputedStyle(parentElem)
    const paddingX = parseFloat(pcs.paddingLeft) + parseFloat(pcs.paddingRight)
    const borderX =
      parseFloat(pcs.borderLeftWidth) + parseFloat(pcs.borderRightWidth)
    const padddingY = parseFloat(pcs.paddingTop) + parseFloat(pcs.paddingBottom)
    const borderY =
      parseFloat(pcs.borderTopWidth) + parseFloat(pcs.borderBottomWidth)
    const pLimitX = pWidth - paddingX - borderX
    const pLimitY = pHeight - padddingY - borderY
    const { width, height } = elem.getBoundingClientRect()
    maxTransition.x = Math.abs(pLimitX - width)
    maxTransition.y = Math.abs(pLimitY - height)
  }

  function trackDrag<T extends ProcessedEvent>(e: T): void {
    const coords = getCoordsOfEvent(e)
    dragDelta.x = coords.x - startPosCoords.x
    dragDelta.y = coords.y - startPosCoords.y

    const dragMod = mode === 'scroll' ? -1 : 1
    x.value = initialX + dragMod * dragDelta.x
    y.value = initialY + dragMod * dragDelta.y
    if (mode === 'transition') processTransitionLimiting()
  }

  function finishDragTrack(e: ProcessedEvent): void {
    isDragging.value = false
    if ((e.target as HTMLElement) === elemRef.value && !isTouch) {
      const preventClick = (ev: MouseEvent) => {
        ev.preventDefault()
        window.removeEventListener('click', preventClick)
      }
      window.addEventListener('click', preventClick)
    }
    startPosCoords.x = 0
    startPosCoords.y = 0
    document.removeEventListener('mousemove', trackDrag)
    document.removeEventListener('touchmove', trackDrag)
  }

  function startDragTrack<T extends ProcessedEvent>(e: T): void {
    const targetTagName = (e.target as HTMLElement).tagName
    if (
      ['INPUT', 'TEXTAREA', 'SELECT'].includes(targetTagName) ||
      isDragging.value
    ) {
      return
    }
    if (!defineIsTouchEvent(e)) {
      e.preventDefault()
      if (e.button !== 0) {
        return
      }
    }
    isDragging.value = true
    initialX = x.value
    initialY = y.value
    const coords = getCoordsOfEvent(e)
    startPosCoords.x = coords.x
    startPosCoords.y = coords.y
    setEventsNames()
    document.addEventListener(moveEventName, trackDrag, { passive: false })
    document.addEventListener(endEventName, finishDragTrack, { passive: false })
  }

  watchEffect(() => {
    if (!elemRef.value) return
    if (mode === 'transition') calcTransitionLimits()
    elemRef.value.addEventListener('mousedown', startDragTrack)
    elemRef.value.addEventListener('touchstart', startDragTrack)
  })

  onBeforeUnmount(() => {
    if (!elemRef.value) return
    elemRef.value.removeEventListener('mousedown', startDragTrack)
    elemRef.value.removeEventListener('touchstart', startDragTrack)
  })

  return {
    x,
    y,
    isDragging,
    arrivedState,
    draggingStyles,
  }
}
